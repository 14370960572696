const loadingSelector = '#loading-indicator';
const errorSelector = '#fatal-error';

const errorHtml = (detail: string) => `
<div class="error">
    <span class="title">Error</span>
    <span class="detail">${detail}</span>
</div>
`;

const setHtml = (querySelector: string, html: string): boolean => {
    const errorElement = document.querySelector(querySelector);
    if (!errorElement) return false;

    errorElement.innerHTML = html;
    return true;
}

export const renderError = (detail: string): void => {
    setHtml(loadingSelector, '');
    if (!setHtml(errorSelector, errorHtml(detail))) {
        throw new Error(`Missing error element, #fatal-error. Error message: ${detail}`);
    }
};

export const clearError = () => {
    setHtml(loadingSelector, '');
    setHtml(errorSelector, '');
};
