// URL query parameter keys
export const urlParamKeys = {
    debug: 'debug',
    // Shared with washere-app
    app: {
        fill: 'fill',
        color: 'color',
        background: 'background',
        showPrivacyPolicy: 'show_privacy_policy',
        kioskAccessToken: 'kiosk_access_token',
        totp: 'totp',
        kioskId: 'kiosk_id',
        size: 'size',
        padding: 'padding',
    },
};
