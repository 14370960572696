import { Kiosk } from "../domain/kiosk";
import { addToUrl } from ".";
import { urlParamKeys } from "../constants";

export const buildClaimUrl = (code: string, kiosk: Pick<Kiosk, 'claimUrl' | 'id'>): string => {
    const { claimUrl, id } = kiosk;
    const { kioskId: kioskIdParam, totp: totpParam } = urlParamKeys.app;

    return addToUrl(claimUrl, { [kioskIdParam]: id, [totpParam]: code });
};
