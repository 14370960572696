import * as Sentry from "@sentry/browser";
import { environment } from "./environment";
import fetchKiosk from "./fetch-kiosk";
import { documentReady, getUrlParams, IntervalTicker, setHeadParamsMetaData, setHeadEnvMetaData } from "./utils";
import { renderError, clearError } from "./render-error";
import { renderApp, renderTick } from "./render";
import { bootApp } from "./boot-app";

import './views/styles.css';

bootApp();

setHeadEnvMetaData();
const parsedParams = getUrlParams();
setHeadParamsMetaData(parsedParams);

if (environment.debug) {
    console.log('parsedParams', parsedParams);
    console.log('environment', environment);
}

const bootQrKiosk = async () => {
    const apiFetchTicker = new IntervalTicker(environment.apiFetchInterval);
    const qrcodeTicker = new IntervalTicker(environment.qrCodeInterval);
    let qrInitialized = false;

    const { kioskAccessToken } = parsedParams;
    if (typeof kioskAccessToken !== "string" || kioskAccessToken === "") {
        const errorMessage = "You must pass a valid kiosk_access_token URL parameter.";
        renderError(errorMessage);
        throw new Error(errorMessage);
    }

    apiFetchTicker.startInterval(async () => {
        try {
            const data = await fetchKiosk(kioskAccessToken)
            qrcodeTicker.startInterval(renderTick({ ...data }, parsedParams))
            qrInitialized = true;
            clearError();
        } catch(e) {
            Sentry.captureException(e);

            if (qrInitialized === false) {
                renderError("Something went wrong fetching Kiosk data. Please try again.");
            }

            throw e;
        }
    });
}

const bootQr = () => {
    const paramName = "content";
    const { padding, fill, background, size, showPrivacyPolicyUrl } = parsedParams;

    const url = new URL(window.location.href);
    const qrUrl = url.searchParams.get(paramName);
    if (!qrUrl) {
      const errorMessage = `You must pass a valid '${paramName}' URL parameter.`;
      renderError(errorMessage);
      throw new Error(errorMessage);
    }

    renderApp(qrUrl, {
      padding,
      fill,
      background,
      size,
      showPrivacyPolicyUrl,
    });
}

documentReady().then(() => {
    if (["/qr.html", "/qr"].includes(window.location.pathname)) {
      bootQr();
    } else {
      bootQrKiosk();
    }

    if (environment.debug) {
        document.body.classList.add('debug');
    }
});
