import { environment } from "./environment";
import { initSentry } from "./init-sentry";

export const bootApp = (): void => {
    if (location.search.includes('debug=1') || location.search.includes('debug=true')) {
        environment.debug = true;
        console.log('DEBUG', true);
    }

    initSentry();
};
