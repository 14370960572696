import * as Sentry from "@sentry/browser";
import { environment } from "./environment";

export const initSentry = (): void => {
    if (!environment.sentryDsn) {
        console.warn('No SENTRY_DSN found, skipping initialization...');
        return;
    }

    if (environment.debug) {
        console.log("Initializing Sentry...");
    }

    Sentry.init({
        dsn: environment.sentryDsn,
        environment: environment.appEnv,
        release: environment.commitSha,
    });
};
