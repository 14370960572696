import queryString from "query-string";
import { numberOrDefault } from ".";
import { urlParamKeys } from "../constants";

const { debug: debugKey } = urlParamKeys;
const {
  padding: paddingKey,
  size: sizeKey,
  color: colorKey,
  fill: fillKey,
  background: backgroundKey,
  kioskAccessToken: kioskAccessTokenKey,
  showPrivacyPolicy: showPrivacyPolicyKey,
} = urlParamKeys.app;

export interface AppQueryParams {
    padding: number;
    size: number;
    fill: string;
    background: string;
    kioskAccessToken: string | null;
    showPrivacyPolicyUrl: boolean;
    debug: boolean;
}

const toColor = (color: string | (string | null)[] | null): string => {
    const cssColorRegex = new RegExp("^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"); // minus leading #
    if (cssColorRegex.test(color as string)) return `#${color}`;

    return color as string;
};

const defaultPadding = 4;
const defaultQrCodeSize = 448; // px
const defaultFillColor = '101010';
export const getUrlParams = (): AppQueryParams => {
    const query = queryString.parse(location.search);
    const size = numberOrDefault(query[sizeKey], defaultQrCodeSize);
    const padding = numberOrDefault(query[paddingKey], defaultPadding);

    const kioskAccessToken = query[kioskAccessTokenKey] as string | null;

    const fill = toColor(query[fillKey] || query[colorKey] || defaultFillColor);
    const background = toColor(query[backgroundKey]);

    const showPrivacyPolicyParam = query[showPrivacyPolicyKey] ?? "true";
    const showPrivacyPolicyUrl = showPrivacyPolicyParam === "true" || showPrivacyPolicyParam === "1";
    const debug = query[debugKey] === "true" || query[debugKey] === "1";

    return { padding, size, fill, background, kioskAccessToken, showPrivacyPolicyUrl, debug };
};
