import QrCreator from "qr-creator";

const defaultQROptions: QRCodeOptions = {
    radius: 0.5,
    ecLevel: "M",
    fill: "#101010",
    background: null,
    size: 400,
};

export interface QRCodeOptions {
    radius?: number;
    ecLevel?: "L" | "M" | "Q" | "H"; // see https://www.qrcode.com/en/about/error_correction.html/
    fill?: string;
    background?: string | null;
    size?: number;
}

class QRCode {
    static render(data: string, element: HTMLElement, options: QRCodeOptions = {}) {
        new QRCode(element, options).render(data);
    }

    private options: QRCodeOptions;
    private element: HTMLElement;

    constructor(element: HTMLElement, options: QRCodeOptions = {}) {
        this.element = element;
        this.options = { ...defaultQROptions, ...options };
    }

    render(text: string): void {
        this.element.innerHTML = "";

        QrCreator.render(
            { ...this.options, text: text },
            this.element
        );
    }
}

export default QRCode;
