import { Kiosk } from "./domain/kiosk";
import { environment } from "./environment";

const gqlQuery = `
query Query($publicAccessToken: String!) {
  getPublicAccessKiosk(publicAccessToken: $publicAccessToken) {
    id
    eventId
    title
    description
    claimUrl
    totpSecretKey
    totpCodeLength
    totpMaxValidPeriods
    totpRotationPeriodSeconds
  }
}
`;

const gqlVariables = (publicAccessToken: string) => {
  return { publicAccessToken: publicAccessToken, };
};

const fetchKiosk = async (publicAccessToken: string): Promise<Kiosk> =>
    await fetch(environment.graphqlUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', },
        body: JSON.stringify({
            query: gqlQuery,
            variables: gqlVariables(publicAccessToken),
        }),
    }).then((res) => res.json())
      .then((json) => json.data.getPublicAccessKiosk);

export default fetchKiosk;
