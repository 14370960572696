// Version control
const COMMIT_SHA = process.env.COMMIT_SHA || process.env.CF_PAGES_COMMIT_SHA;

// App settings
const APP_ENV = process.env.APP_ENV;
const API_FETCH_INTERVAL = process.env.API_FETCH_INTERVAL;
const QR_CODE_INTERVAL = process.env.QR_CODE_INTERVAL;

const GRAPHQL_URL = process.env.GRAPHQL_URL;
const PRIVACY_POLICY_URL = process.env.PRIVACY_POLICY_URL;
const SENTRY_DSN = process.env.SENTRY_DSN;

if (!GRAPHQL_URL) {
    throw new Error('GRAPHQL_URL must be set!');
}

if (!APP_ENV) {
    throw new Error('APP_ENV must be set!');
}

if (!API_FETCH_INTERVAL) {
    throw new Error('API_FETCH_INTERVAL must be set!');
}

if (!QR_CODE_INTERVAL) {
    throw new Error('QR_CODE_INTERVAL must be set!');
}

interface Environment {
    readonly commitSha?: string;
    readonly appEnv: string;
    readonly apiFetchInterval: number;
    readonly qrCodeInterval: number;
    readonly graphqlUrl: string;
    readonly privacyPolicyUrl?: string;
    readonly sentryDsn?: string;
    debug: boolean; // must be possible to mutate
}

export const environment: Environment = {
    commitSha: COMMIT_SHA,
    apiFetchInterval: +API_FETCH_INTERVAL * 1000,
    qrCodeInterval: +QR_CODE_INTERVAL * 1000,
    graphqlUrl: GRAPHQL_URL,
    privacyPolicyUrl: PRIVACY_POLICY_URL,
    sentryDsn: SENTRY_DSN,
    appEnv: APP_ENV,
    debug: false, // this value can be set at runtime
};
