import TOTP from "@cotract/totp";
import { Kiosk } from "./domain/kiosk";
import { environment } from "./environment";
import QRCode, { QRCodeOptions } from "./qr-code";
import { buildClaimUrl } from "./utils";

type RenderOptions = {
    padding: number,
    size: number,
    fill: string,
    background: string,
    showPrivacyPolicyUrl: boolean,
};

interface RenderQrOptions extends QRCodeOptions {
    padding: number,
}

const renderQrCode = (url: string, options: RenderQrOptions): void => {
  const element = document.getElementById("qrcode-img");
  if (!element) return;

  element.style.padding = `${options.padding}px`

  if (options.background) {
    element.style.backgroundColor = options.background;
  }

  const size = options.size ? options.size - (options.padding * 2) : options.size;
  QRCode.render(url, element, {...options, size });
};

const renderQrCodeUrl = (url: string): void => {
    const element = document.getElementById("qrcode-url");
    if (!element) return;

    element.innerHTML = `
        <div class="qrCodeUrl">
            <a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>
        </div>
    `;
};

const renderPrivacyPolicyUrl = (): void => {
    const url = environment.privacyPolicyUrl;
    if (!url) return;

    const element = document.getElementById("qrcode-privacy-policy-url");
    if (!element) return;

    element.innerHTML = `
        <div class="qrCodePrivacyPolicyUrl">
            <a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>
        </div>
    `;
};

const renderTextContent = (title?: string, description?: string) => {
    const titleElement = document.getElementById("title");
    if (!titleElement) return;

    const descriptionElement = document.getElementById("description");
    if (!descriptionElement) return;

    titleElement.innerText = title || "";
    descriptionElement.innerText = description || "";
}

export const renderApp = (
    url: string,
    options: RenderOptions,
    kiosk?: {
        title: string,
        description: string
    },
): void => {
    const { title, description } = kiosk || {};
    const { fill, background, size, padding } = options;

    renderTextContent(title, description);
    renderQrCode(url, { size, fill, background, padding });
    renderQrCodeUrl(url);

    if (options.showPrivacyPolicyUrl) {
        renderPrivacyPolicyUrl();
    }
};

export const renderTick = (kiosk: Kiosk, options: RenderOptions) => {
    const {
        totpSecretKey,
        totpRotationPeriodSeconds: period,
        totpMaxValidPeriods: maxSteps,
        totpCodeLength: digits
    } = kiosk;
    const totp = new TOTP(totpSecretKey, { period, maxSteps, digits });

    return () => {
        const code = totp.generate();
        const url = buildClaimUrl(code, kiosk);

        renderApp(url, options, kiosk);
    };
}
