import { environment } from "../environment";
import { appendMetaTag } from "./append-meta-tag";
import { AppQueryParams } from "./get-url-params";

export const setHeadEnvMetaData = () => {
    appendMetaTag('app-env', environment.appEnv);
    appendMetaTag('git-commit-sha', environment.commitSha ?? '');
    appendMetaTag('api-polling-interval', environment.apiFetchInterval.toString());
    appendMetaTag('qr-render-interval', environment.qrCodeInterval.toString());
    appendMetaTag('graphql-url', environment.graphqlUrl);
    appendMetaTag('privacy-policy-url', environment.privacyPolicyUrl ?? '');
};

export const setHeadParamsMetaData = (params: AppQueryParams): void => {
    appendMetaTag('kiosk-access-token', params.kioskAccessToken || "");
    appendMetaTag('qr-code-fill', params.fill);
    appendMetaTag('qr-code-size', params.size.toString());
    appendMetaTag('qr-show-privacy-policy-url', params.showPrivacyPolicyUrl.toString());
    appendMetaTag('app-debug', params.debug.toString());
};
