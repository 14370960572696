export class IntervalTicker {
    private intervalId: number;
    private tickInterval: number;

    constructor(interval: number) {
        this.intervalId = -1;
        this.tickInterval = interval;
    }

    startInterval(ticker: Function) {
        clearInterval(this.intervalId);

        ticker();
        this.intervalId = setInterval(ticker, this.tickInterval);
    }
}
