import queryString from "query-string";

export const addToUrl = (url: string, queryObject: Record<string, any>) => {
    const parsed = queryString.parseUrl(url, { parseFragmentIdentifier: true });

    const additions = Object.entries(queryObject)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

    const query = Object.entries(parsed.query)
        .map(([key, value]) => `${key}=${value}`)
        .concat(additions)
        .join('&');

    let fragment = '';
    if (parsed.fragmentIdentifier) {
        fragment = `#${parsed.fragmentIdentifier}`;
    }

    return `${parsed.url}?${query}${fragment}`;
};
